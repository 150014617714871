<template>
  <div class="slingshot-wrapper">
    <div ref="slingshotContainer" class="slingshot-container"></div>
  </div>
</template>

<script>
import Matter from "matter-js";
import { roundTo } from "@/utils/utils";

export default {
  components: {},
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      engine: null,
      render: null,
      runner: null,
      width: 0,
      height: 0,
      ball: null,
      sling: null,
      ground: null,
      initialBallPosition: { x: 0, y: 0 },
      dragging: false,
      isDragDetached: false,
      shoot: {
        x: 0,
        y: 0,
      },
      ballRadius: 0,
    };
  },
  computed: {},
  methods: {
    calculateDimensions() {
      // Obtener el ancho del contenedor
      this.width = this.$refs.slingshotContainer.getBoundingClientRect().width;
      this.height = this.$refs.slingshotContainer.getBoundingClientRect().height;
    },
    handleResize() {
      console.log("resize");
      // Recalcular las dimensiones cuando se redimensiona la ventana
      this.calculateDimensions();

      if (this.render) {
        // Actualizar el tamaño del canvas de Matter.js
        this.render.canvas.width = this.width;
        this.render.canvas.height = this.height;

        // También ajustar el tamaño en el motor
        this.render.options.width = this.width;
        this.render.options.height = this.height;
      }
    },
    emitShot() {
      this.$socket.client.emit("gameMessage", {
        type: "controllerData",
        controllerType: "slingshot",
        name: this.block.blockName,
        input: {
          distanceY: roundTo(this.shoot.y ?? 0, 2),
          distanceX: roundTo(this.shoot.x ?? 0, 2),
        },
      });

      console.log("emit", this.shoot);
    },
    setupMatterJS() {
      const {
        Engine,
        Render,
        Runner,
        World,
        Bodies,
        Mouse,
        MouseConstraint,
        Constraint,
        Events,
      } = Matter;

      // Crear el motor de Matter.js
      this.engine = Engine.create();
      const { world } = this.engine;

      // Crear el renderizador usando las dimensiones calculadas
      this.render = Render.create({
        element: this.$refs.slingshotContainer,
        engine: this.engine,
        options: {
          width: this.width,
          height: this.height,
          wireframes: false, // Renderizado sólido
          background: "transparent",
        },
      });

      // Crear el runner
      this.runner = Runner.create();
      Runner.run(this.runner, this.engine);

      Render.run(this.render);

      // Posición relativa del anclaje (punto A del slingshot)
      const anchor = { x: this.width * 0.5, y: this.height * 0.2 };

      const image = this.block.image;

      this.ballRadius = this.width * 0.15; // Radio relativo al ancho del contenedor

      if (image?.url) {

        this.ball = Bodies.circle(anchor.x, anchor.y, this.ballRadius, {
          restitution: 0.7,
          render: {
            sprite: {
              texture: image.url,
              xScale: (this.ballRadius * 2) / image.width,
              yScale: (this.ballRadius * 2) / image.height,
            }
          },
        });
      } else {
        this.ball = Bodies.circle(anchor.x, anchor.y, this.ballRadius, {
          restitution: 0.7,
          render: {
            fillStyle: `#${this.$store.state.user.profile.color}`
          }
        });
      }

      // Crear la restricción (slingshot)
      this.sling = Constraint.create({
        pointA: anchor,
        bodyB: this.ball,
        stiffness: 0.05,
        damping: 0.1,
        render: { visible: false },
      });

      const groundHeight = 20; // Altura fija para el suelo
      this.ground = Bodies.rectangle(
        this.width / 2,
        this.height - 10,
        this.width,
        groundHeight,
        {
          isStatic: true,
          render: {
            fillStyle: "transparent",
          },
        }
      );

      // Añadir el slingshot y el proyectil al mundo
      World.add(world, this.sling);
      World.add(world, this.ball);
      World.add(world, this.ground);

      // Crear el mouse constraint
      const mouse = Mouse.create(this.render.canvas);
      const mouseConstraint = MouseConstraint.create(this.engine, {
        mouse: mouse,
        constraint: {
          stiffness: 0.2,
          render: {
            visible: false,
          },
        },
      });

      World.add(world, mouseConstraint);

      this.initialBallPosition = {
        x: this.ball.position.x,
        y: this.ball.position.y,
      };

      Events.on(mouseConstraint, "startdrag", (event) => {
        console.log("startdrag");

        if (this.isDragDetached) {
          World.add(world, mouseConstraint);
          this.isDragDetached = false;
        }

        if (event.body === this.ball) {
          Matter.Body.setAngularVelocity(this.ball, 0);
          this.dragging = true;
          this.ball.isStatic = false;
        }
      });

      Events.on(mouseConstraint, "mousemove", () => {
        if (this.dragging) {
          if (this.ball.position.y < this.initialBallPosition.y) {
            this.ball.isStatic = true;
          }
        }
      });

      // Detectar cuando el mouse suelta el círculo
      Events.on(mouseConstraint, "enddrag", (event) => {
        if (event.body === this.ball && this.dragging) {
          console.log("endDrag");
          this.dragging = false;

          if (!this.ball.isStatic) {
            this.shoot.y =
              (this.ball.position.y - this.initialBallPosition.y) /
              (this.ground.position.y -
                this.ballRadius -
                this.initialBallPosition.y);
            
            this.shoot.x = this.normalizeXValue(this.ball.position.x);
            
            this.emitShot();
          }

          this.ball.isStatic = false;
        }
      });

      Events.on(this.engine, "collisionStart", (event) => {
        const pairs = event.pairs;

        pairs.forEach((pair) => {
          const { bodyA, bodyB } = pair;

          // Verificar si la pelota colisiona con el suelo
          if (
            (bodyA === this.ball && bodyB === this.ground) ||
            (bodyB === this.ball && bodyA === this.ground)
          ) {
            // Finalizar el arrastre
            this.dragging = false;

            console.log("colision");
            this.shoot.y = 1;
            this.shoot.x = this.normalizeXValue(this.ball.position.x);
            this.emitShot();

            // Desactivar el MouseConstraint
            World.remove(this.engine.world, mouseConstraint);

            this.isDragDetached = true;
            Matter.Body.setAngularSpeed(this.ball, 0.05);
          }
        });
      });
    },
    cleanupMatterJS() {
      // Detener el motor y limpiar el renderizador
      Matter.Render.stop(this.render);
      Matter.Runner.stop(this.runner);

      // Eliminar el canvas del DOM
      this.render.canvas.remove();
      this.render.textures = {};

      // Limpiar el mundo de Matter.js
      Matter.World.clear(this.engine.world);
      Matter.Engine.clear(this.engine);
    },
    normalizeXValue(currentX) {
      // Calcular el desplazamiento X relativo
      const xDisplacement = currentX - this.initialBallPosition.x;
      
      // Normalizar a un rango de -0.5 a 0.5
      // Usando la mitad del ancho como referencia para el máximo desplazamiento
      return Math.max(-0.5, Math.min(0.5, xDisplacement / (this.width / 2)));
    },
  },
  mounted() {
    setTimeout(() => {
      this.calculateDimensions();
      this.setupMatterJS();
      window.addEventListener("resize", this.handleResize);
    }, 100);
  },
  beforeDestroy() {
    this.cleanupMatterJS();
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped lang="scss">
.slingshot-wrapper {
  position: relative;
  width: 100%;
  aspect-ratio: 1;

}
.slingshot-container {
  width: 100%;
  height: 100%;
  margin-bottom: -10px;
  overflow: hidden;
  z-index: 1;
  position: relative;

  &:before {
    content: '';
    width: 5%;
    height: 60%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23aaa' d='M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z'/%3E%3C/svg%3E");
    background-size: 100%;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: 20%;
    z-index: -1;
  }


}
</style>
